import React from 'react';
import Article from 'components/help/helpArticle';
import Image from 'components/help/helpImage';
import CopyCodeSection from 'components/help/installCodeInstruction';
import Link from 'components/ui/link';

import img3 from 'img/help/wix/wix_1.png';
import img4 from 'img/help/wix/wix_2.png';
import img5 from 'img/help/wix/wix_3.png';
import img6 from 'img/help/wix/wix_4.png';
import logo from 'img/help/integrations/wix.png';

const Content = () => {
  return (
    <div>
      Using LiveSession requires placing a simple JavaScript code into your website. Read this
      article to learn how to install our LiveSession tracking code on WiX website.
      <div className="alert alert-primary mb-5 mt-5" role="alert">
        To add our JavaScript code to your WiX website you need premium account on WiX.
      </div>
      <CopyCodeSection />
      <ul className="list-unstyled">
        <li>
          Go to Settings in your WiX site&apos;s dashboard.
          <Image src={img3} lazy alt="Wix integration" title="Go to settings" />
        </li>
        <li>
          Click on the Tracking & Analytics tab under Advanced Settings.
          <Image src={img4} lazy alt="Wix integration" title="Click on tracking & analytics" />
        </li>
        <li>
          Click on New Tool and select &quot;Custom&quot;.
          <Image src={img5} lazy alt="Wix integration" title="New tool > 'Custom'" />
        </li>
        <li>
          Insert your LiveSession code into first input. Type in &quot;LiveSession&quot; into script
          name. Select &quot;Add Code to: All Pages&quot; and then choose to &quot;Load code on each
          new page&quot;. Finally select &quot;Place Code in: Head&quot; method.
          <Image src={img6} lazy alt="Wix integration" title="Insert your LiveSession code" />
        </li>
        <li>
          After that you&apos;re ready to go! Check if your script works properly using{' '}
          <Link href="/help/how-to-check-if-my-tracking-script-works/"> this guide</Link>.
        </li>
      </ul>
    </div>
  );
};

const related = [
  {
    link: '/help/how-to-install-livesession-script/',
    title: 'How to install LiveSession script?',
    description: 'Check how can you install LiveSession on any website.',
  },
  {
    link: '/help/how-to-check-if-my-tracking-script-works/',
    title: 'How to check if my tracking script works',
    description: 'Check if your tracking code is installed correctly',
  },
];

const Wrapped = Article(Content);

export const frontmatter = {
  metaTitle: 'How to install LiveSession on WiX?',
  metaDescription:
    'Easy steps to add LiveSession tracking code to a website powered by WiX landing page builder.',
  canonical: '/help/how-install-on-wix/',
  logo,
  logoStyle: {
    maxWidth: 100,
  },
};

export default () => (
  <Wrapped title={frontmatter.metaTitle} related={related} section="Get Started" {...frontmatter} />
);
